import {
  API_URL,
  AuthService,
  ErrorInterceptor,
  LoggerService,
  LoginComponent,
  MonitoringService,
  SharedConstantService,
  SharedLogoutComponent,
  SharedPermissionService,
  SharedUserService,
  WithCredentialsInterceptor,
} from '@agroone-front/shared'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy, RouterModule } from '@angular/router'
import { environment } from '@front-app-environments/environment'
import { NbMenuModule, NbWindowModule } from '@nebular/theme'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { forkJoin, lastValueFrom, switchMap } from 'rxjs'

import { MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppInjector } from './core/app-injector'
import { CoreModule } from './core/core.module'
import { GlobalErrorHandler } from './core/message/services/error-handler.service'
import { PermissionService } from './core/user/permission.service'
import { UserService } from './core/user/user.service'
import { MdRouteReuseStrategy } from './shared/main-filter/md-route-reuse-strategy'
import { RegionService } from './shared/region/region.service'
import { ThemeModule } from './theme/theme.module'
import { TranslationsLoader } from './translations.loader'

export const init =
  (
    userService: SharedUserService,
    regionService: RegionService,
    loggerService: LoggerService,
    authService: AuthService
  ) =>
  async () => {
    // todo: remove the initialization lines below after migrating them to libs/
    loggerService.init(environment.logLevel, environment.serverLogLevel)
    authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.apiUrl)

    return lastValueFrom(
      forkJoin([userService.init()]).pipe(switchMap(() => forkJoin([regionService.init(), userService.init()])))
    )
  }

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoginComponent,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
      },
    }),
    SharedLogoutComponent,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    ToastModule,
    NbMenuModule.forRoot(),
    NbWindowModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
  ],
  providers: [
    MessageService,
    {
      provide: SharedUserService,
      useClass: UserService,
    },
    {
      provide: SharedPermissionService,
      useClass: PermissionService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [SharedUserService, RegionService, LoggerService, AuthService],
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: MdRouteReuseStrategy,
    },
    {
      provide: API_URL,
      useValue: environment.apiUrl,
    },
    MonitoringService,
    LoggerService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector)
  }
}
